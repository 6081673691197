import Vue from 'vue'
import App from './App.vue'
import InstantSearch from 'vue-instantsearch';
// import 'instantsearch.css/themes/algolia.css';

// import router from './router'
// import store from './store'

Vue.filter('formatCurrency', function (value, emptyText) {
  if (!value) return emptyText;
  if (isNaN(value)) return value;
  const temp = parseFloat(value);
  return '$' + temp.toFixed(2);
});

Vue.config.productionTip = false
Vue.use(InstantSearch);

new Vue({
  // router,
  // store,
  render: h => h(App)
}).$mount('#app')
