<template>
  <div id="app" class="container">
    <search-vehicles v-if="showPartouts" />
    <search v-else />
  </div>
</template>

<script>
import Search from './views/Search.vue';
import SearchVehicles from './views/SearchVehicles.vue';

export default {
  components: {
    Search,
    SearchVehicles,
  },
  computed: {
    showPartouts() {
      if (window._partouts) return true;
      return false;
    }
  }
}
</script>
// <style lang="scss">
// #app {
//   // font-family: 'Avenir', Helvetica, Arial, sans-serif;
//   // -webkit-font-smoothing: antialiased;
//   // -moz-osx-font-smoothing: grayscale;
//   // text-align: center;
//   // color: #2c3e50;
// }
// </style>
