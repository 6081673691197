<template>
  <ais-instant-search
    :search-client="searchClient"
    index-name="prod_PARTS">
    <ais-search-box
      placeholder="Search"
    >
      <template slot="submit-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewbox="0 0 18 18"
          width="16"
          height="16"
        >
          <g 
            fill="none"
            fill-rule="evenodd"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.67"
            transform="translate(1 1)"
          >
            <circle cx="7.11" cy="7.11" r="7.11"></circle>
            <path d="m16 16l-3.87-3.87"></path>
          </g>
        </svg>
      </template>
    </ais-search-box>
    <ais-configure :hits-per-page.camel="15" 
      :filters="retrieveFilters()"/>
    <section class="container-results pt8">
      <ais-infinite-hits>
        <div slot-scope="{ items, refinePrevious, refineNext }">
          <ul class="cards">
            <li v-for="item in items" :key="item.objectID" class="card">
                <div class="card-img">
                  <img :src="imageUrl(item)" class="card-img-top">
                </div>
                <div class="card-body">
                  <p class="card-title">{{ item.partType }}</p>
                  <p class="card-subtitle">
                    <ais-highlight attribute="modelYear" :hit="item" />
                    <ais-highlight attribute="make" :hit="item" />
                    <ais-highlight attribute="model" :hit="item" />
                  </p>
                  <p class="card-text">
                    <ais-highlight attribute="title" :hit="item" />
                  </p>
                </div>
                <div>
                  <p class="price">
                    {{ item.price | formatCurrency }}
                  </p>
                  <div class="shipping">
                    {{ calculateShipping(item) }}
                  </div>
                </div>
                <div class="card-actions">
                  <a :href="item.url">View Part</a>
                </div>
            </li>
          </ul>
          <div style="text-align: center;">
            <a class="pagination-button" @click.stop="refineNext">Show More</a>
          </div>
        </div>
      </ais-infinite-hits>
    </section>
  </ais-instant-search>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';

  export default {
    name: 'search',
    data() {
      return {
        searchClient: algoliasearch(
          'KLV1OKW2J6',
          'c0894dc50052ff31f3a958598ddc0ba8'
        ),
        yardId: (window._yardId || null),
      }
    },
    methods: {
      imageUrl(item) {
        if (item.mainImage) {
          return `https://s3.amazonaws.com/img.partsbeast.com/yards/${item.yardId}` + 
            `/parts/${item.objectID}/medium/${item.mainImage}`;
        }
        return '/portals/0/noimage.jpg';
      },
      calculateShipping(item) {
        if (item.freeShipping) return 'Free Shipping';
        const value = item.shippingAmount;
        if (!value) return '';
        if (isNaN(value)) return '';
        const temp = parseFloat(value);
        return 'Shipping: $' + temp.toFixed(2);
      },
      retrieveFilters() {
        if (this.yardId) {
          return `yardId = ${this.yardId}`;
        }
        return '';
      }
    }
  }
</script>

<style lang="scss">
$green: rgb(71,180,117);
$dark-gray: #333;
$muted: #6c757d;
$highlight-color: yellow;
$card-radius: 3px;
$screen-sm: "only screen and (max-width : 600px)";
$screen-md: "only screen and (min-width : 601px) and (max-width : 999px)";
.ais-SearchBox {
  //margin-top: 32px;
  .ais-SearchBox-input {
    background-color: #fff;
    border-radius: 8px;
    height: 64px;
    font-size: 1.5rem;
    padding: 4px 48px 4px 76px;
    width: 100%;
    border: 1px solid rgba(0,0,0,.1);
    outline: none;
    box-shadow: 0 0 8px 2px rgba(0,0,0,.2);
  }
  .ais-SearchBox-input:focus {
    box-shadow: 0 0 10px 2px rgba(darken($green, 20%),.4);
    border: 1px solid $green;
  }
  .ais-SearchBox-input::placeholder {
    font-size: 1.5rem;
    font-weight: 500;
  }
  .ais-SearchBox-submit[type=submit] {
    width: 64px;
    height: 64px;
    padding: 0 16px 0 32px;
    border: 0;
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    position: absolute;
    top: 32px; // 64px;
    left: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    margin-bottom: 0;
    svg {
      position: absolute;
      right: 25px;
    }
  }
  .ais-SearchBox-reset {
    position: absolute;
    top: 20px;
    right: 30px;
    width: 32px;
    height: 32px;
    background-color: transparent;
    border: 0px none;
    svg {
      path {
        fill: $green;
      }
    }
  }
  @media #{$screen-sm} {
    .ais-SearchBox-input {
      box-shadow: none;
      padding: 4px 16px 4px 40px;
    }
    .ais-SearchBox-submit[type=submit] {
      padding: 0 8px;
      width: 32px;
      left: auto;
      svg {
        right: 8px;
      }
    }
  }
}
.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 auto;
  width: 100%;
  padding: 0;
} // .cards
.card {
  list-style: none;
  width: 31%;
  flex: 0 1 auto;
  margin: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
  min-height: 100%;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: $card-radius;

  mark {
    padding: 0;
    background-color: $highlight-color
  }
  
  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  .card-img-top {
    border-radius: $card-radius $card-radius 0 0;
    max-width: 100%;
    width: 100%;
  }
  .card-body {
    flex: 1 1 auto;
    padding: .5em;
  }
  .card-title {
    text-transform: uppercase;
    font-size: 1.8rem;
    font-weight: 400;
    color: $dark-gray;
    margin-bottom: .5rem;
  }
  .card-subtitle {
    font-size: 1.6rem;
    color: $muted;
    span {
      margin-right: 5px;
    }
  }
  .card-text {
    color: black;
    font-size: 1.5rem;
  }
  .price {
    font-size: 3rem;
    color: $green;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 4px;
  }
  .shipping {
    text-align: center;
    margin-bottom: 16px
  }
  .card-actions {
    
    a {
      display: block;
      text-align: center;
      text-transform: uppercase;
      font-size: 1.8rem;
      font-weight: bold;
      background-color: $green;
      color: white;
      padding: 1em;
      border-bottom-left-radius: $card-radius;
      border-bottom-right-radius: $card-radius;
    }
  }
} // .card

@media #{$screen-sm} {
  .card {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
@media #{$screen-md} {
  .card {
    width: 47%;
  }
}


.pagination-button {
  display: inline-block;
  color: $green;
  border: 2px solid $green;
  background-color: white;
  font-size: 12px;
  margin: 8px 0;
  height: 50px;
  text-transform: uppercase;
  line-height: 46px;
  min-width: 200px;
  padding-left: 26px;
  padding-right: 26px;
  text-align: center;
  &:hover {
    background-color: $green;
    color: white;
    cursor: pointer;
  }
}
.ais-Pagination {
  margin: 0;
  padding: 0;
  font-size: 2rem;
  li {
    display: inline-block;
    a {
      margin-right: 4px;
      padding: 4px 10px;
      border-radius: 5px;
      border: 1px solid rgba($green,.5);
      color: $green;
    }
  }
}
</style>
